import React from "react";
import { Route, Routes } from 'react-router-dom'
import Hash from '~P/Hash';
import { Layout, Anchor, Menu } from 'antd';
const { Content, Header, Footer } = Layout;
const { Link } = Anchor;

export default class extends React.Component{
    render(): React.ReactNode {
        return (
            <Layout className="layout">
                <Header>
                    <div className="logo">
                        低效开发工具仓
                    </div>
                    <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
                        <Menu.Item key="1">哈希</Menu.Item>
                    </Menu>
                </Header>
                <Content style={{padding: "20px 50px"}}>
                    <Routes>
                        <Route path="/" element={<Hash />} />
                    </Routes>
                </Content>
                <Footer style={{textAlign: "center"}}>
                    <Anchor affix={false}>
                        <Link href="https://beian.miit.gov.cn/" target="_blank" title="粤ICP备2022024936号-1" />
                    </Anchor>
                    
                </Footer>
            </Layout>
            
        )
    }
}