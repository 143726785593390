import React from 'react';
import CryptoJS from 'crypto-js';
import { Input, Row, Col, Checkbox, Button, Radio, RadioChangeEvent, Divider, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
const {TextArea} = Input;

export default class extends React.Component<any, any> {
    hashFn:(msg:string, secretKey?: string)=>string
    oriVal:string
    autoTrans:boolean
    isHmacHash:boolean
    constructor(props:any){
        super(props);
        this.state = {
            rltVal: "",
            showSecretKey: false,
            secretKey: ""
        }
        this.hashFn = this.genHashFn("MD5");
        this.oriVal = "";
        this.autoTrans = false;
        this.isHmacHash = false;
    }
    oriContentChg = (evt:React.ChangeEvent<HTMLTextAreaElement>):void => {
        this.oriVal = evt.target.value;
        
        if(this.autoTrans){
            this.doHash();
        }
    }
    secretKeyChg = (evt:React.ChangeEvent<HTMLInputElement>):void => {
        this.setState({
            secretKey: evt.target.value
        },()=>{
            if(this.autoTrans){
                this.doHash();
            }
        })
    }
    typeChg = (evt:RadioChangeEvent):void => {
        var algType:string = evt.target.value;
        // 判断是否Hmac方法
        var isHmac = algType.indexOf("Hmac") > -1;
        this.setState({
            showSecretKey: isHmac,
            secretKey: ""
        });
        this.hashFn = this.genHashFn(algType);
    }
    // 生成Hash方法
    genHashFn = (fnType:string):any => {
        return Object.getOwnPropertyDescriptor(CryptoJS,fnType)?.value;
    }
    doHash = ():void => {
        if(typeof this.hashFn === 'function'){
            if(!this.state.showSecretKey){
                this.setState({
                    rltVal: this.hashFn(this.oriVal)
                });
            }else{
                this.setState({
                    rltVal: this.hashFn(this.oriVal, this.state.secretKey)
                });
            }
            
        }
    }
    render(): React.ReactNode {
        return (
            <>
                <Radio.Group defaultValue="MD5" onChange={this.typeChg}>
                    <Radio.Button value="MD5">MD5</Radio.Button>
                    <Radio.Button value="SHA1">SHA1</Radio.Button>
                    <Radio.Button value="SHA224">SHA224</Radio.Button>
                    <Radio.Button value="SHA256">SHA256</Radio.Button>
                    <Radio.Button value="SHA384">SHA384</Radio.Button>
                    <Radio.Button value="SHA512">SHA512</Radio.Button>

                    <Divider></Divider>

                    <Radio.Button value="HmacMD5">HmacMD5</Radio.Button>
                    <Radio.Button value="HmacSHA1">HmacSHA1</Radio.Button>
                    <Radio.Button value="HmacSHA224">HmacSHA224</Radio.Button>
                    <Radio.Button value="HmacSHA256">HmacSHA256</Radio.Button>
                    <Radio.Button value="HmacSHA384">HmacSHA384</Radio.Button>
                    <Radio.Button value="HmacSHA512">HmacSHA512</Radio.Button>
                </Radio.Group>
                
                <Divider></Divider>
                <Row gutter={16} justify="space-around" align="middle">
                    <Col span={10} className="gutter-row">
                        <TextArea style={{width: '100%'}} rows={10} onChange={this.oriContentChg}></TextArea>
                    </Col>
                    <Col span={4} className="gutter-row">
                        <Button onClick={this.doHash}>{"Hash>>"}</Button>
                        {
                            this.state.showSecretKey ? (
                                <>
                                    <Divider/>
                                    <Input placeholder='密钥' value={this.state.secretKey} onChange={this.secretKeyChg} />
                                </>
                            ) : null
                        }
                        <Divider/>
                        <Checkbox onChange={(evt:CheckboxChangeEvent)=>{this.autoTrans = evt.target.checked}} >
                            自动处理
                        </Checkbox>
                    </Col>
                    <Col span={10} className="gutter-row">
                        <TextArea style={{width: '100%'}} rows={10} disabled={true} value={this.state.rltVal}></TextArea>
                    </Col>
                </Row>
            </>
            
        )
    }
}